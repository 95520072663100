/**
 * App
*/
import LocomotiveScroll from 'locomotive-scroll';


document.addEventListener('DOMContentLoaded', function() {
    document.getElementsByTagName('body')[0].classList.remove('opener-is-visible');

    setTimeout(() => {
        const scroll = new LocomotiveScroll({
            el: document.querySelector('#js-scroll'),
            smooth: true,
            getSpeed: true,
            getDirection: true,
            useKeyboard: true
        });


        scroll.on("call", (value, way) => {
            if (value === 'BACKGROUND_COLOR') {
                if(way === 'enter') {
                    document.getElementsByTagName('body')[0].classList.add('opener-is-visible');
                } else {
                    document.getElementsByTagName('body')[0].classList.remove('opener-is-visible');
                }
            }
        });

    }, 500);

    if( typeof console === 'object' ) {
        const flame = String.fromCodePoint(0x1F525);
        const heart = String.fromCodePoint(0x1F49C);

        console.log('%c%s',
        'color: white; background: black; font-size: 18px;',
        '\n' +
        ' Made with ' + heart +'
Email us at info@yeet.ai');
    }
});